import { GetStaticPaths, GetStaticPropsContext } from 'next'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import { useContentfulLiveUpdates } from '@contentful/live-preview/react'

import { Layout } from '@components/layouts'
import { getAllSlugs, getPageBySlug, getHeaderAndFooter } from 'services/contentful'
import { Default404Page, loadFallback } from 'utils'
import { ContentfulPgPage, TemplateContentType, ContentfulTmSidebar, ReservedPages } from 'types'

const TmSidebar = dynamic(() => import('@templates/tm-sidebar').then((mod) => mod.TmSidebar))
const TmGeneric = dynamic(() => import('@templates/tm-generic').then((mod) => mod.TmGeneric))

export type NotFoundPgPage = { notFound: boolean }

export type PgPageProps = ContentfulPgPage<
  | {
      CONTENT_TYPE: TemplateContentType
    }
  | undefined
>

export const getStaticPaths: GetStaticPaths = async () => {
  const slugs = await getAllSlugs()
  const filteredSlugs = slugs.filter((slug) => !Object.values(ReservedPages).includes(slug.join('/') as ReservedPages))
  const filteredCatalogSlugs = filteredSlugs.filter(
    (slug) => !slug.includes('catalog') && !slug.includes('page-results'),
  )

  // eslint-disable-next-line
  console.log(
    '\nGenerating static pages for the following slugs:\n',
    filteredCatalogSlugs.map((slug) => slug.join('/')),
  )

  return {
    paths: filteredCatalogSlugs.map((slug) => ({
      params: { slug },
    })),
    fallback: 'blocking', // if hit route and doesn't exist, Next will block request and return it.
  }
}

export const getStaticProps = async ({ params, preview }: GetStaticPropsContext<{ slug: [] }>) => {
  const slug = params?.slug?.join('/') ?? '/'

  const isPreview = preview ?? false

  const page = await getPageBySlug(slug, isPreview)

  if (!page) {
    return {
      notFound: true,
    }
  }

  const { footerContentful, headerContentful } = await getHeaderAndFooter(page, isPreview)

  return {
    props: {
      ...page,
      footer: footerContentful,
      header: headerContentful,
      isPreview,
      notFound: false,
      revalidate: 10, // Added the revalidate option to trigger incremental static regeneration.
    },
  }
}

const PgPage = (props: PgPageProps | NotFoundPgPage) => {
  const update = useContentfulLiveUpdates(props)
  const router = useRouter()
  if (router.isFallback) return loadFallback()

  if ((update as NotFoundPgPage).notFound) {
    return <Default404Page />
  }

  const page = update as PgPageProps

  const { seo, isPreview, header, footer, slug, announcement } = page

  return (
    <Layout
      slug={slug}
      isPreview={isPreview}
      seo={seo}
      header={{ ...header, announcement: announcement || header.announcement }}
      footer={footer}
    >
      {page.template?.CONTENT_TYPE === 'tmSidebar' ? (
        <TmSidebar {...(page.template as ContentfulTmSidebar)} />
      ) : (
        <TmGeneric blocks={page.blocks} />
      )}
    </Layout>
  )
}

export default PgPage
